import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { selectExtraData } from '../../../../state'

import { BaseArticleComponent } from 'src/app/shared/components/base-article-component/base-article-component'
import { Dictionary } from '@ngrx/entity'
import { Plan } from '../plan-article.model'
import { MixpanelService } from 'src/app/services/mixpanel.service'
import { AsyncPipe, DatePipe } from '@angular/common'
import { PlanViewComponent } from '../../../../shared/components/plan-view/plan-view.component'
import { NgScrollbarModule } from 'ngx-scrollbar'

@Component({
    selector: 'app-plan-article',
    templateUrl: './plan-article.component.html',
    styleUrls: ['./plan-article.component.scss'],
    standalone: true,
    imports: [NgScrollbarModule, PlanViewComponent, AsyncPipe, DatePipe]
})
export class PlanArticleComponent extends BaseArticleComponent<Plan>  {

    public extraData$ : Observable<Dictionary<any>> = new Observable


    constructor(
        router: Router,
        route: ActivatedRoute,
        store: Store<Plan>,
        mixPanelService: MixpanelService,
        ) {
            super(router, route, store, mixPanelService, 'data/plans')
            this.extraData$ = this.store.select(selectExtraData)
    }
}
